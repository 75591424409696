<template>
  <div>
    <TopNavVue/>
    <div>
      <el-row class="cloudDesktop">
        <el-col :span="22" :offset="1" class="wga">
          <h6 class="title">磐石一体机</h6>
          <p>磐石一体机面向工作组级安全桌面用户，运用超融合技术将计算资源、存储资源和网络资源实现一体化管理，采用模块化设计，支持弹性发布，降低实施成本，开机即用。</p>
          <p>磐石一体机实现了资源模块化的横向弹性伸缩，形成统一的计算与存储资源池，不仅可以减少数据中心服务器数量，整合IT资源，达到提高资源利用率和降低整体拥有成本的目的；而且可以利用软件定义数据中心技术，建立一个安全的、资源可按需调配的数据中心环境，为业务部门提供成本更低、服务水平更高的IT基础架构，从而能够针对业务部门的需求做出快速的响应。</p>
          <img src="@/assets/aqwg/aq3.png" />
        </el-col>
        <el-col :span="22" :offset="1" class="wga" style="margin-top: 4%;">
          <h6 class="title">产品介绍</h6>
          <p>企业级私有云基于超融合架构，仅需通用的X86服务器和交换机硬件，利用软件定义技术实现计算、存储、网络完全资源池化，即可替代繁重复杂的传统工厂业务架构，实现云架构应用。解决数据中心投入成本高、运维工作量大、扩展性差等问题，提高工厂投资经济效益，提升运维和管理工作效率，并且实现“开箱即用”的便利性。该产品系面向组级用户提供的快捷部署方案，可以有效满足50~100用户规模的应用场景。</p>
          <img src="@/assets/aqwg/ytj.png" />
          <!-- <img src="@/assets/aqwg/aq34.png" /> -->
        </el-col>
        <el-col :span="22" :offset="1" class="wga" style="margin-top: 4%;">
          <h6 class="title" style="margin-bottom: 20px;">产品特色</h6>
          <div class="dxb" v-for="(item,index) in proFunction" :key="index">
            <div class="imgbox">
              <img :src="item.imgSrc"/>
            </div>
            <h6>{{item.title}}</h6>
            <p>{{item.desc}}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <footerMobileVue/>
  </div>
</template>

<script>
import TopNavVue from '../../../components/topNav.vue'
import footerMobileVue from '../../../components/footerMobile.vue';
export default {
  name:'psytjMobile',
  components:{footerMobileVue,TopNavVue},
  data(){
    return {
      proFunction:[
        {title:'开箱即用',desc:'按照用户实际应用场景，以及资源需求规模，实现开箱即用，简化部署流程。',imgSrc:require('@/assets/aqwg/a20.png'),},
        {title:'按需定制',desc:'根据客户资源需求，进行合理配置和部署，既保障业务正常使用，同时避免盲目资源投入，节省用户投入。',imgSrc:require('@/assets/aqwg/a21.png'),},
        {title:'方便扩容',desc:'根据用户规模，系统平台提供充足的扩容通道，实现对用户资源平滑升级，节约用户投资。',imgSrc:require('@/assets/aqwg/a23.png'),},
        {title:'信息安全',desc:'有效提升数据安全性，既规避数据被篡改的可能，又提供完整的数据备份，确保生产数据得到备份，保障数据安全。',imgSrc:require('@/assets/aqwg/a24.png'),},
        {title:'定义终端',desc:'通过软件定义终端的模式，对终端新构，利用现有电脑安装部署专门的终端软件实现桌面终端的重新利用。',imgSrc:require('@/assets/aqwg/a19.png'),},
        {title:'简化运维',desc:'改变传统PC一对一的维护模式，只需维护好一体机组件服务器，即可以保障业务正常运行。',imgSrc:require('@/assets/aqwg/a22.png'),},
      ],
    }
  }
  
}
</script>

<style scoped>
  .cloudDesktop{background: #f5f5f5;padding-top: 17%;padding-bottom: 20px;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .wga{}
  .wga p{font-size: 3vw;color: #888;width: 100%;margin: 15px auto;text-indent: 2em;}
  .wga img{width: 100%;}
  .dxb{width: 100%;margin-bottom: 15px;padding-bottom: 15px; box-shadow: 0px 0px 10px #ccc;}
  .imgbox{padding-top: 10px;}
  .imgbox img{width: 80px;display: block;margin: 10px auto;}
  .dxb h6{font-size: 4vw;color: #333;display: block;margin-top: 10px;margin-bottom: 5px;text-align: center;}
  .dxb p{font-size: 3vw;color: #888;display: block;width: 80%;margin: 15px auto 0px;text-align: justify;text-indent: 2em;}
</style>